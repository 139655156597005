import Box from '@mui/material/Box';
import { Slider} from '@mui/material';
import { useState } from 'react';
import '../sliders.css'


const valueText =  (value) => {
    return `Day ${value}`
}


const WeekSlider = ({ index, timeSlot, alterCopy }) => {
  const [isTwo, setIsTwo] = useState(timeSlot.length > 1 ? true : false)
  const [val1, setVal1] = useState(timeSlot[0])
  const [val2, setVal2] = useState(!isTwo ? [7,7] : timeSlot[1])
  
  const marks = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value:7,
      label:"7",
              }
  ];
 

  


  const handleSlide1Change = (e, value) => {
    if(isTwo){
      const copy = [...val2]


      if(value[0] === val2[0] || value[1] === val2[0]) {
        
        setVal2([copy[0] + 1, copy[1]])
        setVal1(value)
        alterCopy(index ,value, val2)
      }
       if(value[0] === val2[1] || value[1] === val2[1]) {
        
        setVal2([copy[0] , copy[1] + 1])
        setVal1(value)
        alterCopy(index ,value, val2)
       }
        if(value[0] >= 7) {
          value[0] = 6
        } 
        if(value[1] >= 7) {
          value[1] = 6
        }
        setVal1(value)
        alterCopy(index ,value, val2)
      } else {
    
      setVal1(value)
      
      alterCopy(index, value)

      }
  }

  const handleSlide2Change = (e, value) => {
       const copy = [...val1]
      if(value[0] === val1[0] || value[1] === val1[0] ){
          setVal1([copy[0] - 1,copy[1] ])
          setVal2(value)
          alterCopy(index, val1, value)
          
      } 
      if(value[0] === val1[1] || value[1] === val1[1] ){
        setVal1([copy[0], copy[1] - 1])
        setVal2(value)
        alterCopy(index, val1, value)
    
  
  } if(value[0] <= 1) {
    value[0] = 2
  } if(value[1] <= 1) {
    value[1] = 2
  }
  setVal2(value)
  alterCopy(index, val1, value)
     
  }
    
    
  const increment = () => {
    setIsTwo(!isTwo)
    if(isTwo === true){
     
      alterCopy(index, val1)
    } else {
     
      setVal2([7,7])
      alterCopy(index, val1, val2)
    }
  }

  return (
    <Box sx={{width:500, display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center', margin:3}}>
      <h2 className='week-text'>Week{index + 1}</h2>
      
      {
        isTwo ?

          <div className='slider-container'><Slider
            style={{ zIndex: 10 }}
            
            className='bottom-slider'
            min={1}
            max={7}
            marks={marks}
            getAriaLabel={() => 'availability range'}
            value={val1}
            onChange={handleSlide1Change}
            valueLabelDisplay="on"
         
            getAriaValueText={valueText}
           
          > </Slider>

            <Slider
              
              className='slider2'
              style={{ zIndex: 20 }}
              min={1}
              max={7}
           
              getAriaLabel={() => 'availability range'}
              value={val2}
              onChange={handleSlide2Change}
              valueLabelDisplay="on"
              getAriaValueText={valueText}

            >

            </Slider></div> : <div className='slider-container'><Slider
              style={{ zIndex: 10 }}
              className='bottom-slider'
              min={1}
              max={7}
              marks={marks}
              getAriaLabel={() => 'availability range'}
              value={val1}
              onChange={handleSlide1Change}
              valueLabelDisplay="on"
              getAriaValueText={valueText}

            >

            </Slider>
          </div>
      }





      {isTwo ?
        <button className='slider-btn' onClick={() => increment()}>-</button>

        : <button className='slider-btn' onClick={() => increment()}>+</button>
      }
    </Box>
  )

}

export default WeekSlider