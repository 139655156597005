import logo from './logo.svg'
import './App.css'
import Slider from './components/Slider'
import { useState } from 'react'
import WeekSlider from './components/Slider'
/* styling works atleast works on 768px and under screens
 */

function App () {
  const [timeSlots, setTimeSlots] = useState([
    {
      week: [
        [1, 3],
        [4, 6]
      ]
    },
    {
      week: [[1, 3]]
    },

    {
      week: [[1, 3]]
    },

    {
      week: [[1, 3]]
    },

    {
      week: [[1, 3]]
    },

    {
      week: [[1, 3]]
    },

    {
      week: [[1, 3]]
    }
  ])

  let copy = [...timeSlots]

  const handleChange = () => {
    setTimeSlots([...copy])
    alert('Check console for "saved" timeslot data')
    console.log(timeSlots)
  }

  const alterCopy = (index, value1, value2) => {
    if (!value2) {
      copy[index].week = [value1]
    }
    copy[index].week = [value1, value2]
  }

  return (
    <div className='slider-div'>
      <h1 className='header'>Select your availability for the next 7 weeks</h1>
      {copy.map((data, index) => {
        
        return (
          <WeekSlider
            key={index}
            index={index}
            timeSlot={data.week}
            alterCopy={alterCopy}
          ></WeekSlider>
        )
      })}
      <div className='btn-div'>
        <button className='savebtn' onClick={() => handleChange()}>
          save
        </button>
      </div>
    </div>
  )
}

export default App
